<template>
  <div class="online-setting fd-w-full fd-h-full fd-bg-white fd-px-4 fd-py-6">
    <div class="fd-w-full fd-flex fd-items-center fd-justify-start fd-mb-4">
      <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('onlineSetting.onlineSetting') }}</span>
    </div>
    <div class="fd-mt-7 fd-px-3">
      <span class="fd-text-lg fd-font-bold fd-text-theme-6 fd-mb-3 fd-block">
        {{ $t('onlineSetting.uploadCoverPhoto') }}
      </span>
      <UploadPhoto :name="'cover'" :postAction="uploadApi" @refreshData="getData" :option="onlineSettingCover[0]" />
    </div>

    <div class="fd-mt-5 fd-px-3">
      <span class="fd-text-lg fd-font-bold fd-text-theme-6 fd-mb-3 fd-block">
        {{ $t('onlineSetting.uploadSecondarySlot') }}
      </span>
      <UploadPhoto :name="'secondary_cover'"  :postAction="uploadApi" @refreshData="getData" :option="onlineSettingSecondaryCover[0]" />
    </div>
    <b-toast id="example-toast" title="BootstrapVue" static></b-toast>
  </div>
</template>

<script>
import UploadPhoto from "@/views/components/ProjectManagement/onlineSetting/UploadPhoto";
import {mapGetters} from "vuex";
import BaseService from "@/http/baseService";
export default {
  name: "Media",
  components: {UploadPhoto},
  data() {
    return {
      baseUrl: null
    }
  },
  created() {
    this.baseUrl = BaseService.getInstance().getBaseUrl()
    this.getData()
  },
  computed: {
    ...mapGetters({
      onlineSettingCover: 'project/onlineSettingCover',
      onlineSettingSecondaryCover: 'project/onlineSettingSecondaryCover'
    }),
    uploadApi() {
      return this.baseUrl + `/projects/${this.$route.params.id}/media`
    }
  },
  methods: {
    getData() {
      this.$store.dispatch('project/getOnlineSettingMedia', this.$route.params.id)
    }
  }
}
</script>

